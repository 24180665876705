import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BiometryLivenessStatus } from '../../../services/bff/biometry/dto/BiometryLiveness'

interface BiometryReducerState {
  isSubmittingBiometryLiveness: boolean
  isPoolingBiometryLivenessResult: boolean

  biometryLivenessStatus?: BiometryLivenessStatus
}

const initialState: BiometryReducerState = {
  isSubmittingBiometryLiveness: false,
  isPoolingBiometryLivenessResult: false,
}

export const BiometryLivenessReducer = createSlice({
  name: 'biometry-liveness',
  initialState,
  reducers: {
    submitBiometryLiveness: (state, action: PayloadAction<SubmitBiometryLivenessAction>) => {
      return { ...state, isSubmittingBiometryLiveness: true, isPoolingBiometryLivenessResult: true }
    },
    submitBiometryLivenessSuccess: state => {
      return { ...state, isSubmittingBiometryLiveness: false, isPoolingBiometryLivenessResult: true }
    },
    submitBiometryLivenessError: state => {
      return { ...state, isSubmittingBiometryLiveness: false }
    },
    endBiometryLivenessStatusPooling: (state, action: PayloadAction<EndBiometryLivenessStatusPoolingAction>) => {
      return { ...state, isPoolingBiometryLivenessResult: false, biometryLivenessStatus: action.payload.status }
    },
  },
})

export default BiometryLivenessReducer

export interface SubmitBiometryLivenessAction {
  biometryObject: string
  imageBase64: string
}

export interface EndBiometryLivenessStatusPoolingAction {
  status: BiometryLivenessStatus
}
